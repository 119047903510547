import { SetStateAction, useEffect, useState } from "react";
import { SearchCreatorsTelegram } from "../createCampaign/components/searchTelegramCreators";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Button } from "@mui/material";
import { addCreatorToExistingCampaign } from "./controller.campaign";
import { SearchCreators } from "../createCampaign/components/searchCreators";


export default function ReplaceCreators(){

    const [selectedCreatorList,setSelectedCreatorList] = useState<any[]>([]);
    const paramData = useParams();
    const stateData = useLocation();
    const navigation = useNavigate();

    useEffect(()=>{
        console.log("paramData: ", stateData.state.data);
    },[])

    return(
        <div style={{padding: 25}}>
            <CreatorSearch>
            <p>{" If you are in this page it means a creator probably rejected your Campaign, don't worry we have alot  more creators to choose from"}</p>
            <h1>{"Your Total Budget will Increase starting from " + stateData.state.data.TotalPrice + 'ETB - Rejected Creator'}</h1>
            </CreatorSearch>
          {
            stateData.state.data.days > 0 ? 
            <SearchCreatorsTelegram setSelectedCreatorList={setSelectedCreatorList} socialMedia={2} />
            :
            <SearchCreators setSelectedCreatorList={setSelectedCreatorList} socialMedia={0} />
          }

            <Container>
        <AddCreator onClick={()=>{
            addCreatorToExistingCampaign({creatorList: selectedCreatorList, campaignId: stateData.state.data.CampaignId}).then((res: any) => {
                alert("Successfully Added Creator");
                 navigation("/dashboard/business/campaignDetail/"+stateData.state.data.CampaignId);
            }).catch((err) => {
                console.log(err);
            })



        }}>
            Add Creator
        </AddCreator>
                </Container>

        </div>
    )
}

const AddCreator = styled.button`
    background-color: #090D25;
    border: 1px solid #F8F8F8;
    border-radius: 5px;
    color: white;
    padding: 10px;
    margin-top: 10px;
    cursor: pointer;
    font-size: 15px;
    width: 200px;
`;

const CreatorSearch = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #F8F8F8;
    align-self: flex-start;
    z-index: 0;
    margin-top: 30px;
    p {
        align-text: start;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: white;
    align-self: flex-start;
    z-index: 0;
    p {
        align-text: start;
    }
`;