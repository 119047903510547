import styled from 'styled-components';
import ConnectTelegram from "../../dashboard/telegram/connetTelegram/connectTelegram";
import { TbRuler2 } from 'react-icons/tb';
import { RiTelegramFill } from 'react-icons/ri';
import { MdLocationCity, MdOutlineDocumentScanner, MdOutlineLocationCity, MdPerson } from 'react-icons/md';
import { GiModernCity } from 'react-icons/gi';
import { FaTreeCity } from 'react-icons/fa6';
import { AiFillBank, AiOutlineNumber } from 'react-icons/ai';
import { useEffect, useState } from 'react';
import { getBusinessDetails } from './controller.profile';
// import { getCreatorDetails, updateProfileInfo } from './controller.profile';


export default function BusinessProfileNew() {

    const [businessDetail, setBusinessDetail] = useState<any>({});

    useEffect(() => {
        getBusinessDetails().then((res: any) => {
            console.log("CONN: ", res);
            setBusinessDetail(res);
        }).catch((err) => {
            console.log("CONN: ", err);

            console.log(err)

        })

    }, []);
    const handelChange = (name: string, e: any) => {
        setBusinessDetail((prevState: any) => ({
            ...prevState,
            [name]: e.target.value,
        }));
    }

    return (
        <ProfilePageParent>
            <ProfilePageContainer>
                {false && <ConnectTelegram closeTelegram={() => { }} token={""} />}
                <HoverCardProfile height={'440px'}>
                    {/* <AvatarImage src={businessDetail.identificationImage} alt="Avatar" /> */}
                    <NameProfile>
                        <span>{businessDetail.name ? businessDetail.name[0] : ""}</span>
                    </NameProfile>
                    <ProfileTitle>{businessDetail.name}</ProfileTitle>
                    <ProfileEmail>{businessDetail.email}</ProfileEmail>
                    <TelegramContainer>
                        <TelegramInfo>
                            <TelegramIcon />
                            <TelegramText>Telegram</TelegramText>
                        </TelegramInfo>
                        <ConnectButton
                            onClick={() => {

                            }}
                        >
                            {false ? "Connected" : "Connect"}
                        </ConnectButton>
                    </TelegramContainer>
                </HoverCardProfile>
                <div>
                    <HoverCardProfile height={'210px'}>
                        <CardTitle>
                            <h1>Location Information </h1>
                        </CardTitle>

                        <EditableContent>
                            <div style={{ display: 'flex' }}>
                                <GiModernCity color={'#090D25'} size={20} style={{ margin: 5, marginTop: 0 }} />
                                <p>{"Location"} </p>
                            </div>

                            <input onChange={(e: any) => handelChange("subCity", e)} type="text" value={businessDetail.address} />
                        </EditableContent>

                    </HoverCardProfile>

                    {/* <HoverCardProfile height={'210px'}>
                        <CardTitle>
                            <h1>Bank Information </h1>
                        </CardTitle>
                        <EditableContent>
                            <div style={{ display: 'flex' }}>
                                <AiFillBank color={'#090D25'} size={20} style={{ margin: 5, marginTop: 0 }} />
                                <p>{"Bank Name"} </p>
                            </div>

                            <input  onChange={(e: any) => handelChange("bankName", e)}  type="text" value={businessDetail.bankName} />
                        </EditableContent>

                        <EditableContent>
                            <div style={{ display: 'flex' }}>
                                <MdPerson color={'#090D25'} size={20} style={{ margin: 5, marginTop: 0 }} />
                                <p>{"Account Holder"} </p>
                            </div>

                            <input onChange={(e: any) => handelChange("bankUserName", e)}  type="text" value={businessDetail.bankUserName} />
                        </EditableContent>

                        <EditableContent>
                            <div style={{ display: 'flex' }}>
                                <AiOutlineNumber color={'#090D25'} size={20} style={{ margin: 5, marginTop: 0 }} />
                                <p>{"Number"} </p>
                            </div>

                            <input  onChange={(e: any) => handelChange("bankAccountNumber", e)} type="text" value={businessDetail.bankAccountNumber} />
                        </EditableContent>



                    </HoverCardProfile> */}

                </div>

                {/* <div>
                    <HoverCardProfile height={'210px'}>
                        <CardTitle>
                            <h1>Tin Number Information </h1>
                        </CardTitle>
                        <EditableContent>
                            <div style={{ display: 'flex' }}>
                                <AiOutlineNumber color={'#090D25'} size={20} style={{ margin: 5, marginTop: 0 }} />
                                <p>{"Tin Number"} </p>
                            </div>

                            <input onChange={(e: any) => handelChange("tinNumber", e)} type="text" value={businessDetail.tinNumber} />
                        </EditableContent>
                        <EditableContent>
                            <div style={{ display: 'flex' }}>
                                <MdOutlineDocumentScanner color={'#090D25'} size={20} style={{ margin: 5, marginTop: 0 }} />
                                <p>{"Licence"} </p>
                            </div>

                            <input type="text" value={"Licence"} />
                        </EditableContent>
                    </HoverCardProfile>


                    <HoverCardProfile height={'210px'}>
                        <CardTitle>
                            <h1>About  </h1>
                        </CardTitle>
                        <EditableContent>
                            <div style={{ display: 'flex' }}>
                                <MdPerson color={'#090D25'} size={20} style={{ margin: 5, marginTop: 0 }} />
                                <p>{"About Me"} </p>
                            </div>

                        </EditableContent>
                        <textarea  onChange={(e: any) => handelChange("about", e)} style={{fontSize: 12,borderRadius: 5}} value={businessDetail.about} />


                    </HoverCardProfile>
                </div> */}
            </ProfilePageContainer>
            <SaveChanges onClick={() => {
                // updateProfileInfo(creatorDetail).then((res) => {
                //     console.log("RES: ", res);
                // }
                // ).catch((err) => {
                //     console.log("ERRR : ", err);
                // })

            }}>Save Changes</SaveChanges>
        </ProfilePageParent>
    );
}

const NameProfile = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
width: 100%;
height: 240px;
background-color: #090d25f0;
color: white;
display: flex;
align-items: center;
border-radius: 5px;
span{
    font-size: 100px;
    margin: 10px;
    text-align: center;
}
`;

const ProfilePageParent = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
background-color: #F0F0F0;
height: 900px;
`;

const SaveChanges = styled.button`
padding: 10px;
border-radius: 5px;
background-color: lightblue;
color: #0088cc;
border: none;
cursor: pointer;
margin: 20px;
align-self: start;
margin-left: 35px;
&:hover {
    background-color: #f0f0f0;
}
`;


const ProfilePageContainer = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
justify-content: flex-start;
width: 100%;
// height: 100vh;
background-color: #F0F0F0;
padding-left: 20px;
flex-wrap: wrap;
overflowY: scroll;
`;

const HoverCardProfile = styled.div<any>`
background-color: #fff;
border-radius: 5px;
padding: 10px;
box-shadow: 0px 0px 5px rgba(0,0,0,0.1);
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1) !important;
margin-bottom: 10px;
cursor: pointer;
width: ${(props: any) => (props.width ? props.width : '400px')};
height: ${(props: any) => (props.height ? props.height : '200px')};
margin: 20px;

textarea {
    background-color: #f0f0f0;
    width: 90%;
    height: 90px;
    padding: 5px;
}
`;

const AvatarImage = styled.img`
width: 100%;
height: 240px;
border-radius: 5px;
object-fit: cover;
`;

const ProfileTitle = styled.h1`
font-size: 24px;
margin-top: 10px;
text-align: start;
`;

const ProfileEmail = styled.h3`
font-size: 16px;
color: #333;
text-align: start;
color: gray;
`;

const TelegramContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
color: #0088cc;
width: 100%;
align-self: flex-start;
background-color: lightgray;
margin: auto;
margin-top: 10px;
padding: 10px;
border-radius: 5px;
`;

const TelegramInfo = styled.div`
display: flex;
align-items: center;
`;

const TelegramIcon = styled(RiTelegramFill)`
margin: 5px;
font-size: 22px;
color: #0088cc;
`;

const TelegramText = styled.p`
margin: 5px;
font-size: 16px;
`;

const ConnectButton = styled.button`
padding: 5px;
border-radius: 5px;
background-color: #fff;
color: #0088cc;
border: none;
cursor: pointer;

&:hover {
    background-color: #f0f0f0;
}
`;

const CardTitle = styled.div`
width: 90%;
height: 40px;
border-radius: 5px;
// background-color: #090d25eb;
display: flex;
align-items: center;
margin-left: 15px;
justify-content: flex-start;
align-self: center;
color: #090d25eb;
font-weight: bolder;
text-align: flex-start;


`;

const EditableContent = styled.div`
display: flex;
flex-direction: row;
padding: 10px;
width: 100%;
border-radius: 15px;
// border: 0.5px solid lightgray;
height: 40px;
margin-top: 10px;
// box-shadow: 0px 0px 5px rgba(0,0,0,0.1);
 justify-content: space-between;

 p{
    font-size: 13px;

 }
 input{
    border: none;
    width: 120px;
    height: 100%;
    border-radius: 15px;
    padding: 10px;
    margin-right: 10px;
    font-size: 13px;
    outline: none;
}
`;