import VideoCard from "../../../component/video/video";
import "./style.tutorial.css"
import React from 'react';
export default function Tutorial(){

    return(
        <div className="homeMain">
        {/* <div>
<h1 className="FAQheader">
          Tutorial
        </h1>
</div> */}
      <div className="FAQ">
        
  
    
     
          <VideoCard title="How to Add/Connect your Telegram Channel to zeterads.com" url="https://www.youtube.com/embed/-g5tXDs1LEs?si=Yl6Jhy79ZiTFQYFO" description="  How to Add/Connect your Telegram Channel to zeterads.com content."/>
          <VideoCard title="How to create a creator/influencer account on zeterads.com" url="https://www.youtube.com/watch?v=uBhXN3nbfSM" description=" How to create a creator/influencer account on zeterads.com content."/>
          <VideoCard title="How to Create Account" url="https://www.youtube.com/watch?v=5lCvkO4fKyc" description="  How to Connect your Youtube Channel to Zeterads.com content."/>
          


   
      </div>
      </div>
    )
}