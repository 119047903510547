import tools from "../../component/assets/images/tools.svg";
import CForm from "../../component/forms/form";
import { formBuilder } from "../../component/forms/prop.form";
import { useState } from "react";
import { getJson } from "../../component/forms/controller.form";
import { Loading } from "../../component/loading/loading";
import "./style.mobile.creatorLogin.css";
import "./style.creatorLogin.css";
import { Link, useNavigate } from "react-router-dom";
import { loginController } from "./controller.login";
import { AxiosResponse } from "axios";
import SuccessToast from "../../component/toast/success";
import logo from "../../component/assets/images/zeter adv white logo1.png";
import ErrorToast from "../../component/toast/error";
import { AiFillHome } from "react-icons/ai";
import FormInput from "../../component/formComponents/input/input";
import { useFormik } from "formik";
import { loginFormik } from "../../util/formik/login";
export default function CreatorLogin() {
  const formik = useFormik({
    ...loginFormik, onSubmit: async () => {
      const userData = { userName: formik.values.username, password: formik.values.password }


      setLoading(true);
      await loginController(userData, "creator")
        .then((res: any) => {
          if (res.data === "Invalid username or password") {
            setErrorValue(
              "invalid username or password please try again!"
            );
            setLoading(false);
          } else {
            let isFirstLogin = localStorage.getItem("isFirstLogin");
            if(!isFirstLogin){
              navigator("/dashboard/tutorial")
              localStorage.setItem("isFirstLogin", "true");
            }else{

              navigator("/dashboard");
            }
          }
        })
        .catch((err) => {
          console.log("error: ", err);
          setShowErrorMessage(true);
          setLoading(false);
        });
    }
  })
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [formData, setFormData] = useState<formBuilder[]>([
    {
      label: "User Name",
      name: "userName",
      value: "",
      type: "TextInput",
      placeholder: "User Name",
      checkListValues: [],
      onChange: () => { },
      required: true,
    },
    {
      label: "Password",
      name: "password",
      value: "",
      type: "TextInput",
      placeholder: "Password",
      checkListValues: [],
      onChange: () => { },
      required: true,
    },
  ]);
  const handleClose = () => {
    setShowErrorMessage(false);
    // Additional logic for closing the component
  };

  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(3);
  const [errorVal, setErrorValue] = useState("");
  const [successMessage, showSuccessMessage] = useState(false);
  const [updatedContent, setUpdatedContent] = useState<formBuilder[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const navigator = useNavigate();
  return (

    <div className="createAccount-back">
      <div className="logoContainer">
        <Link to={"/"}><img className="logo" src={logo} alt="logo" /></Link>


        <div></div>
      </div>




      <div className="createAccount">

        {showErrorMessage && (
          <ErrorToast
            message="Some thing went wrong, please check you connnection and try again!"
            onClose={handleClose}
          />
        )}

        <Loading loading={loading} setLoading={"sef"} />

        <div className="loginContent-login">


          <div className="inline-flex rounded-md shadow-sm w-9/12 mx-4 my-4" role="group">
            <Link to={'/creator_login'} type="button" className="px-4 py-2 w-1/2 text-sm font-medium text-white bg-primary-color border  rounded-s-lg   focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700">
              Creator
            </Link>
            <Link to={'/business_login'} type="button" className="px-4 py-2 w-1/2 text-sm font-medium text-gray-900 bg-transparent border  rounded-e-lg hover:bg-primary-color hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-700 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700">
              Business
            </Link>

          </div>


          <div className={"formWrapper-login"}>
            {errorVal === "" ? (
              <></>
            ) : (
              <div className="LoginerrorContainer">
                <p>{errorVal}</p>
              </div>
            )}
            {/* {CForm(
              formData,
              (updatedDate: formBuilder[]) => {
                setUpdatedContent(updatedDate);
              },
              start,
              end
            )} */}
           

            
            <FormInput type="text" label="User Name" isRequired={true}
              placeholder="User Name" value={formik.values.username}
              errorMessage={formik.errors.username} onChange={(event) => {

                formik.setFieldValue("username", event.target.value);
              }} />
            <FormInput type="password" label="Password" isRequired={true} placeholder="Password" value={formik.values.password}
              errorMessage={formik.errors.password} onChange={(event) => {
                formik.setFieldValue("password", event.target.value);
              }} />
          </div>
          <div className={"actions-login"}>
            {/* <button
              onClick={async () => {
                var content = await getJson(updatedContent || []);
                console.log("content: ", content);
                var contentArray = Object.values(content);
                console.log(contentArray);
                if (contentArray[0] === "") {
                  setErrorValue("Please enter your username.");
                } else if (contentArray[1] === "") {
                  setErrorValue("Please enter your password.");
                } else if (contentArray.length === 0) {
                  setErrorValue("Please enter your username & password.");
                } else {
                  setErrorValue("");
                  setLoading(true);
                  await loginController(content, "creator")
                    .then((res: any) => {
                      if (res.data === "Invalid username or password") {
                        setErrorValue(
                          "invalid username or password please try again!"
                        );
                        setLoading(false);
                      } else {
                        navigator("/dashboard");
                      }
                    })
                    .catch((err) => {
                      console.log("error: ", err);
                      setShowErrorMessage(true);
                      setLoading(false);
                    });
                }
              }}
              className={"roundButton me-1 ms-1 font-semibold text-xs"}
            >
              {"Login"}
            </button> */}
          <button className={"roundButton me-1 ms-1 font-semibold text-xs"} onClick={() => formik.handleSubmit()}>Login</button>
            <div>
              <Link to="/forget_password" className="text-sm font-semibold text-primary-color">
                Forget Password?
              </Link>
            </div>
            <div className="flex flex-row justify-center align-middle items-center">
              <p className="text-sm m-1">Didn't have an account?</p>{" "}
              <Link
                to="/create-account"
                className="text-sm font-bold text-primary-color"
              >
                Register
              </Link>
            </div>
          </div>
        </div>
        <div className="loginDecore">

          <svg className="w-10 h-10 mx-auto mb-3 text-gray-400 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 14">
            <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
          </svg>
          <h1>
          Your Content, Your Cash: Login to Prosperity!
          </h1>
          {/* <img src={tools} alt="tools" /> */}
        </div>
      </div>
    </div>
  );
}
