import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { CreatorData, getCreatorData } from "../../../admin_dashboard/creators/controller.creators";
import { AiOutlineSearch } from "react-icons/ai";

interface SearchCreatorsProps {
    setSelectedCreatorList: Dispatch<SetStateAction<any[]>>;
    socialMedia: number;
}

export function SearchCreatorsTelegram({ setSelectedCreatorList, socialMedia }: SearchCreatorsProps) {
    const [creatorName, setCreatorName] = useState<string>("");
    const [creator, setCreator] = useState<CreatorData[]>([]);
    const [selectedCreators, setSelectedCreators] = useState<CreatorData[]>([]);
    const [selectedDaysWithIndex, setSelectedDaysWithIndex] = useState<any>({});

    const handleSelectCreator = (selectedCreator: CreatorData, selectedDayIndex: number) => {
        if (selectedCreators.some(creator => creator.socialMediaAccountId === selectedCreator.socialMediaAccountId)) {
            const updatedList = selectedCreators.filter(creator => creator.socialMediaAccountId !== selectedCreator.socialMediaAccountId);
            setSelectedCreators(updatedList);
            setSelectedCreatorList(updatedList);
        } else {
            selectedCreator.selectedDayIndex = selectedDayIndex || 0;
            const updatedList = [...selectedCreators, selectedCreator];
            setSelectedCreators(updatedList);
            setSelectedCreatorList(updatedList);
        }
    };

    const handleRemoveSelectedCreator = (id: number) => {
        const updatedList = selectedCreators.filter(creator => creator.socialMediaAccountId !== id);
        setSelectedCreators(updatedList);
        setSelectedCreatorList(updatedList);
    };



    useEffect(() => {
        getCreatorData(0, 40, "another", socialMedia + 1).then((data: CreatorData[]) => {

            // the plan is to group the creators by there social media id 


            let finalObject: any = {};
            data.forEach((element: any) => {

                if (finalObject[element.socialMediaAccountId]) {

                    finalObject[element.socialMediaAccountId] = {
                        ...finalObject[element.socialMediaAccountId],  // Retain existing properties
                        days: [...finalObject[element.socialMediaAccountId].days, element.days],
                        with_pin: [...finalObject[element.socialMediaAccountId].with_pin, element.with_pin],
                        with_out_pin: [...finalObject[element.socialMediaAccountId].with_out_pin, element.with_out_pin],
                        is_with_pin: false,
                        selectedDayIndex: 0
                    };

                } else {
                    finalObject[element.socialMediaAccountId] = {
                        ...element,
                        days: [element.days],
                        with_pin: [element.with_pin],
                        with_out_pin: [element.with_out_pin],
                        is_with_pin: false,
                        selectedDayIndex: 0

                    };
                }
            });
            const arrayData: any[] = Object.values(finalObject);
            setCreator(arrayData);


            console.log("Final Object: ", finalObject)
        }).catch((err) => {
            // Handle error
        });
    }, [])


    // if(socialMedia === 2 && creator.length > 0){
    //   return <SocialMediaTableTelegram data={creator} />
    // }
    return (
        <div>
            <div className="flex flex-col space-y-4">
                {/* Selected Creators */}
                {selectedCreators.length > 0 && (
                    <div className="flex flex-wrap gap-2">
                        {selectedCreators.map((creator) => (
                            <div key={creator.id} className="bg-[#090D25] text-white px-3 py-1 rounded-full flex items-center space-x-2">
                                <img style={{ width: 40, height: 40, borderRadius: 20 }} src={creator.identificationImage} alt="" />
                                <span>{creator.AccountName} </span>
                                <button
                                    onClick={() => handleRemoveSelectedCreator(creator.socialMediaAccountId||0)}
                                    className="text-blue-600 hover:text-white-800 focus:outline-none">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                        ))}
                    </div>
                )}
                <span className="bg-[#090D25] text-white px-3 py-1 rounded flex items-center space-x-1">
                    Total Estimated Budget: 
                    {(selectedCreators.reduce((acc, creator) => {
                        const selectedIndex = creator.selectedDayIndex !== undefined ? creator.selectedDayIndex : 0;
                        return acc + (creator[creator.is_with_pin ? "with_pin" : "with_out_pin"]![selectedIndex])
                    }, 0)).toLocaleString()}
                    </span>

                {/* Search Bar */}
                <div className="flex">
                    <div className="bg-white pt-4 pb-4 w-96 rounded-md">
                        <h1 className="text-lg font-bold text-gray-500 text-left">Search creator</h1>
                        <div className="mt-5 mb-2 border-2 py-1 px-3 flex justify-between rounde-md rounded-md">
                            <input
                                value={creatorName}
                                onChange={(e) => setCreatorName(e.target.value)}
                                className="flex-grow outline-none text-gray-600 focus:text-blue-600"
                                type="text"
                                placeholder="Search creator..."
                            />
                            <span onClick={() => {
                                // setCreator([]);
                                getCreatorData(0, 10, creatorName, socialMedia + 1).then((data: CreatorData[]) => {
                                    
                                    let finalObject: any = {};
                                    console.log("DATA: ", data);
                                    data.forEach((element: any) => {
                        
                                        if (finalObject[element.socialMediaAccountId]) {
                        
                                            finalObject[element.socialMediaAccountId] = {
                                                ...finalObject[element.socialMediaAccountId],  // Retain existing properties
                                                days: [...finalObject[element.socialMediaAccountId].days, element.days],
                                                with_pin: [...finalObject[element.socialMediaAccountId].with_pin, element.with_pin],
                                                with_out_pin: [...finalObject[element.socialMediaAccountId].with_out_pin, element.with_out_pin],
                                                is_with_pin: false,
                                                selectedDayIndex: 0
                                            };
                        
                                        } else {
                                            finalObject[element.socialMediaAccountId] = {
                                                ...element,
                                                days: [element.days],
                                                with_pin: [element.with_pin],
                                                with_out_pin: [element.with_out_pin],
                                                is_with_pin: false,
                                                selectedDayIndex: 0
                                            };
                                        }
                                    });
                                    const arrayData: any[] = Object.values(finalObject);
                                    // setCreator([]);
                                    // alert("TES2");
                                    console.log("FINAL: ", arrayData);
                                    setCreator(arrayData);
                        
                                }).catch((err) => {
                                    // Handle error
                                });
                            }}>
                                <AiOutlineSearch size={30} />
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            {/* Table */}
            <table className="min-w-full leading-normal mt-4">
                <thead>
                    <tr>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Name</th>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Social Media Account</th>

                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Status</th>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Days</th>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Avg View</th>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">With_pin</th>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">With_out_pin</th>


                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"></th>
                    </tr>
                </thead>
                <tbody>
                    {creator && creator.length > 0 && creator.map((singleCreator, mainIndex) => (
                        <tr className={`text-left ${selectedCreators.some(sel => sel.id === singleCreator.id) ? 'bg-blue-100' : ''}`}>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0 w-10 h-10">
                                        <img className="w-full h-full rounded-full" src={singleCreator.identificationImage} alt="" />
                                    </div>
                                    <div className="ml-3">
                                        <p className="text-gray-900 whitespace-no-wrap">{singleCreator.FirstName + " " + singleCreator.LastName}</p>
                                    </div>
                                </div>
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <p className="text-gray-900 whitespace-no-wrap">{singleCreator.AccountName}</p>
                            </td>

                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <span className={`relative inline-block px-3 py-1 font-semibold leading-tight ${singleCreator?.status === "true" ? 'text-green-900' : 'text-red-900'}`}>
                                    <span className={`absolute inset-0 ${singleCreator?.status === "true" ? 'bg-green-200' : 'bg-red-200'} opacity-50 rounded-full`}></span>
                                    <span className="relative">{singleCreator?.status === "true" ? "Active" : "Inactive"}</span>
                                </span>
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <select onChange={(e) => {
                                    const selectedIndex = e.target.selectedIndex;
                                    setSelectedDaysWithIndex({ ...selectedDaysWithIndex, [mainIndex]: selectedIndex });
                                    console.log("Selected Days: ", selectedDaysWithIndex);
                                }}>
                                    {
                                        singleCreator?.days?.map((day: any, index: number) => {
                                            return <option key={index} value={day}>{day + " Days"}</option>
                                        })
                                    }
                                </select>
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <p className="text-gray-900 whitespace-no-wrap">{singleCreator.AvgView}</p>
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">
                                <input
                                onChange={(e) => {
                                    
                                    setCreator((prev) => {
                                        return prev.map((c) => {
                                        console.log("Checked1: ", c.socialMediaAccountId , " - " , singleCreator.socialMediaAccountId);

                                            if (c.socialMediaAccountId === singleCreator.socialMediaAccountId) {
                                                return {
                                                    ...c,
                                                    is_with_pin: true
                                                }
                                            }
                                            return c;
                                    })
                                    });
                                  
                                }}
                                type="checkbox"
                                checked={singleCreator.is_with_pin} // Use the `checked` attribute to control the checkbox
                                style={{ margin: 5 }}
                                />
                                {singleCreator.with_pin ? singleCreator.with_pin[selectedDaysWithIndex[mainIndex] || 0] : 0}
                            </p>
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">
                                <input
                                onChange={(e) => {
                                    console.log("Checked2: ", e.target.checked);
                                    console.log("FOUND ONE: ",singleCreator.id );
                                        
                                    setCreator((prev) => {

                                        return prev.map((c) => {
                                           
                                            if (c.socialMediaAccountId === singleCreator.socialMediaAccountId) {
                                                return {
                                                    ...c,
                                                    is_with_pin: false
                                                }
                                            }
                                            return c;
                                    })
                                    });
                                }}
                                type="checkbox"
                                checked={!singleCreator.is_with_pin} // Use the `checked` attribute to control the checkbox
                                style={{ margin: 5 }}
                                />
                                {singleCreator.with_out_pin ? singleCreator.with_out_pin[selectedDaysWithIndex[mainIndex] || 0] : 0}
                            </p>
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <button
                                    onClick={() => {
                                        if (singleCreator.status === "false" || singleCreator?.status == null) {
                                            alert("Creator is inactive");
                                        } else {
                                            singleCreator.selectedDay = singleCreator.days![selectedDaysWithIndex[mainIndex] || 0];
                                            singleCreator.hours_on_top = singleCreator[ singleCreator.is_with_pin ? "with_pin" : "with_out_pin"]![selectedDaysWithIndex[mainIndex] || 0];
                                            singleCreator.is_with_pin = singleCreator.is_with_pin;
                                            handleSelectCreator(singleCreator, selectedDaysWithIndex[mainIndex] );
                                        }
                                    }}
                                    style={{ backgroundColor: '#090D25', width: 100, borderRadius: 5, color: 'white', padding: 10 }}>
                                    {selectedCreators.some(sel => sel.socialMediaAccountId === singleCreator.socialMediaAccountId) ? "Deselect" : "Select"}
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
