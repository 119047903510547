import axios from "axios";
import { formBuilder } from "../../component/forms/prop.form";
import { BussinessAccountJson } from "./api.model.create_account";
import { url } from "../../util/constant";

export function buildCreatorApiBody(newJson: any) {
  console.log("new Json: ", newJson);
  var businessAccountJson: BussinessAccountJson = {
    user: {
      userName: newJson.userName,
      password: newJson.password,
    },
    business: {
      name: newJson.name,
      email: newJson.email,
      address: newJson.address,
      businessLicense: newJson.businessLicense,
    },
  };

  return businessAccountJson;
}

export async function createAccountApiCall(body: BussinessAccountJson) {
  // TODO: Implement API call here
  return new Promise((resolve, reject) => {
    axios
      .post(url + "/business", body)
      .then(function (response) {
        console.log(response);
        resolve(response);
      })
      .catch(function (error) {
        console.log(error);
        resolve(error);
      });
  });
}


// export async function checkIfUserNamesExistsBusiness(){
 
export async function checkIfUserNamesExistsBusiness(userName: string, email: string) {
  return new Promise((resolve, reject) => {
    axios.post(url + '/creator/check_if_creator_exist/email_or_username',
      {
        "userName": userName,
        "email": email
      })
      .then(function (response) {
        console.log(response);
        resolve(response);
      })
      .catch(function (error) {
        console.log(error);
        resolve(error);
      });
  });
}