
import axios from 'axios';
import {url} from '../../../../util/constant';
import axiosInstance from '../../../../axiosConfig';


export function videoChecker(urlLocaction: string,creatorInA_campaign_id: number) {
    

        var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "")["access_token"];

  return new Promise((resolve, reject) => {
    console.log("HHHHHH: ", urlLocaction.split("v=")[1]);
    var videoUrlId =  urlLocaction.split("v=")[1];
    videoUrlId = "ddv="+videoUrlId;
      axios.get(url+'/campaign/video/'+videoUrlId+'/check/'+creatorInA_campaign_id, {
        headers: {
            "Authorization": "Bearer " + userInfo   
        }
    })
        .then(function (response) {
          console.log("TESTc: " ,response.data);
          
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          alert("EE: "+ JSON.stringify(error));
        resolve(error);
        });
      });  
  
}   



export function videoUpload(campaign_id: number, urlYt: string,orderId: number, socialMediaAccountId: number , socialMediaId: number) {
    var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "")["access_token"];
    var creatorId = JSON.parse(localStorage.getItem("UserInfo") || "")["creatorDetail"]["id"];
    return new Promise((resolve, reject) => {
        axios.post(url+'/campaign/video/create', {
          "creatorId":creatorId,
          "campaignId":campaign_id,
          "orderId": orderId,
          "socialMediaId":socialMediaId,
          "socialMediaAccountId":socialMediaAccountId,
          "nicheId":1,
          "videoUrl":urlYt
      },{
          headers: {
              "Authorization": "Bearer " + userInfo   
          }
      })
          .then(function (response) {
            console.log("TESTc: " ,response);
            resolve(response);
          })
          .catch(function (error) {
            console.log(error);
          reject(error);
          });
        });
    }

export function creator_in_campaign(campaign_id:number,socialMediaAccountId:number){
  var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "")["access_token"];
return new Promise((resolve, reject) => {
  axiosInstance.get(url+'/campaign/creator_in_campaign/'+campaign_id+'/'+socialMediaAccountId, {
    headers: {
        "Authorization": "Bearer " + userInfo   
    }
})
  .then(function (response) {
    console.log("TESTc23: " ,response.data);
    resolve(response.data);
  }).catch((error) => {
    console.log("errorcustom: ", error);   
    reject(error);
})
});
}


export async function getNextPaymentDate(){
  var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "")["access_token"];

  return new Promise((resolve,reject)=>{
    axiosInstance.get(url+'/campaign/pay_days/get', {
      headers: {
          "Authorization": "Bearer " + userInfo   
      }
  })
    .then(function (response) {
      console.log("getNextPaymentDate: " ,response.data);
      resolve(response.data);
    }).catch((error) => {
      reject(error);
    });

  })

}