//react-router-dom
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import Home from "../home/home";
import HowItWorks from "../how_it_works/how_it_works";
import CreateAccount from "../create_account/create_account";
import MainDashboard from "../dashboard/mainDashboard";
import CreatorLogin from "../auth/creatorLogin";
import "url-polyfill";
import GoogleAuth from "../auth/google_oauth/google_oauth";
import MainBusinessDashboard from "../dashboard_business/mainBusinessDashboard";
import Login from "../login/login";
import BusinessLogin from "../auth/businessLogin";
import BusinessCreateAccount from "../business_create_account/create_account";
import Pricing from "../../component/pricing";
import AdminLogin from "../auth/adminLogin";
import AdminDashboard from "../admin_dashboard/adminDashboard";
import Telegram from "../dashboard/telegram/telegram_list/telegram";
import BusinessProfile from "../dashboard_business/profile/business_profile";
import AboutUs from "../about_us/about_us";
import BusinessCreateAccountNew from "../business_create_account/create_account_new";
import { createBrowserHistory } from 'history';
import {NavigationProvider} from '../../hooks/axios403Handler';
import axiosInstance from '../../axiosConfig'; // Import the Axios instance to set up the interceptor
import ForbiddenPage from "../forbidden";
import Policy from "../policy/policy";
import VerifyEmail from "../verify_email/verifyEmail";
import ForgetPassword from "../auth/forgetPassword/forgetPassword";
import ResetPassword from "../auth/forgetPassword/resetPassword";


export default function MainScreen() {


  return (
    <Router>
      <NavigationProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/forbidden" element={<ForbiddenPage />} />
        <Route path="/about_us" element={<AboutUs />} />
        <Route path="/how_it_works" element={<HowItWorks />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/forget_password" element={<ForgetPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />

        <Route
          path="/business-create-account"
          element={<BusinessCreateAccount />}
        />
         <Route
          path="/business-create-account-new"
          element={<BusinessCreateAccountNew />}
        />
        <Route path="/create-account" element={<CreateAccount />} />
        <Route path="/login/" element={<Login />} />
        <Route
          path="/dashboard/"
          element={
            <MainDashboard
              screen={"home"}
              nav={"Pages / Dashboard"}
              headerText="Home | Dashboard"
            />
          }
        />
        <Route
          path="/dashboard/order"
          element={
            <MainDashboard
              screen={"order"}
              nav={"Pages / Orders"}
              headerText="Orders | Dashboard"
            />
          }
        />
        <Route
          path="/dashboard/order/:id"
          element={
            <MainDashboard
              screen={"order_id"}
              nav={"Pages / Order"}
              headerText="Detail | Order"
            />
          }
        />
        <Route
          path="/dashboard/profile"
          element={
            <MainDashboard
              screen={"profile"}
              nav={"Pages / Profile"}
              headerText="Profile | Dashboard"
            />
          }
        />

<Route
          path="/dashboard/tutorial"
          element={
            <MainDashboard
              screen={"tutorial"}
              nav={"Pages / tutorial"}
              headerText="Tutorial | Dashboard"
            />
          }
        />

        <Route
          path="/dashboard/payment"
          element={
            <MainDashboard
              screen={"payment"}
              nav={"Pages / Payment"}
              headerText="Payment | Dashboard"
            />
          }
        />

        <Route
          path="/dashboard/telegram"
          element={
            <MainDashboard
            screen={"telegram"}
            nav={"Pages / Telegram"}
            headerText="Telegram | Dashboard"
          />
          }
        />

<Route
          path="/dashboard/youtube"
          element={
            <MainDashboard
            screen={"youtube"}
            nav={"Pages / Youtube"}
            headerText="Youtube | Dashboard"
          />
          }
        />

        <Route path="/creator_login" element={<CreatorLogin />} />
        <Route path="/business_login" element={<BusinessLogin />} />
        <Route path="/admin_767/login" element={<AdminLogin />} />
        <Route path="/dashboard/youtube_auth" element={<GoogleAuth />} />

        <Route
          path="/dashboard/admin"
          element={
            <AdminDashboard
              screen={"home"}
              nav={"Pages / Dashboard"}
              headerText="Home | Dashboard"
            />
          }
        />



        <Route
          path="/dashboard/admin/pay-creators"
          element={
            <AdminDashboard
              screen={"pay-creators"}
              nav={"Pages / Dashboard"}
              headerText="Pay | Creators"
            />
          }
        />

        <Route
          path="/dashboard/telegram/add-telegram-channel"
          element={
            <MainDashboard
              screen={"addTelegramChannel"}
              nav={"Pages / Telegram / Add"}
              headerText="Telegram | Add"
            />
          }
        />

        <Route 
          path="/dashboard/orderDetail/updateCreator-list"
          element={
            <MainBusinessDashboard
              screen={"updateCreatorList"}
              nav={"Pages / Creator / Update"}
              headerText="Update | Creator"
            />
          }
          />

          <Route 
          path="/verify_email"
          element={<VerifyEmail />}
          />

<Route
          path="/dashboard/youtube/add-Youtube-channel"
          element={
            <MainDashboard
              screen={"addYoutubeChannel"}
              nav={"Pages / Youtube / Add"}
              headerText="Youtube | Add"
            />
          }
        />

        <Route
          path="/dashboard/admin/home"
          element={
            <AdminDashboard
              screen={"home"}
              nav="Pages / Admin Dashboard"
              headerText="Home | Dashboard"
            />
          }
        />
        
        <Route
          path="/dashboard/admin/payment_verefication"
          element={
            <AdminDashboard
              screen={"payment_verefication"}
              nav="Pages / Admin Dashboard"
              headerText="Home | Dashboard"
            />
          }
        />
        <Route
          path="/dashboard/admin/creators"
          element={
            <AdminDashboard
              screen={"creators"}
              nav="Pages / Creators"
              headerText="Home | Creators"
            />
          }
        />

        <Route
          path="/dashboard/admin/businesses"
          element={
            <AdminDashboard
              screen={"businesses"}
              nav="Pages / Businesses"
              headerText="Home | Businesses"
            />
          }
        />

        <Route
          path="/dashboard/admin/income-report"
          element={
            <AdminDashboard
              screen={"income-report"}
              nav="Pages / Income Report"
              headerText="Home | Income Report"
            />
          }
        />

        <Route 
          path="/dashboard/admin/youtube-update"
          element={
            <AdminDashboard
              screen={"youtube-update"}
              nav="Pages / youtube-update"
              headerText="Home | youtube-update"
            />
          }
          />

<Route 
          path="/dashboard/admin/telegram-message"
          element={
            <AdminDashboard
              screen={"telegram-message"}
              nav="Pages / telegram-message"
              headerText="Home | telegram-message"
            />
          }
          />

        <Route
          path="/dashboard/admin/payment-requests"
          element={
            <AdminDashboard
              screen={"payment-requests"}
              nav="Pages / Payment Requests"
              headerText="Home | Payment Requests"
            />
          }
        />


        <Route
          path="/dashboard/admin/reports"
          element={
            <AdminDashboard
              screen={"reports"}
              nav="Pages / Reports"
              headerText="Home | Reports"
            />
          }
        />

        <Route
          path="/dashboard/admin/trending-campaigns"
          element={
            <AdminDashboard
              screen={"trending-campaigns"}
              nav="Pages / Trending Campaigns"
              headerText="Home | Trending Campaigns"
            />
          }
        />

        <Route
          path="/dashboard/business/home"
          element={
            <MainBusinessDashboard
              screen={"home"}
              nav="Pages / Business Dashboard"
              headerText="Home | Dashboard"
            />
          }
        />
        <Route
          path="/dashboard/business/campaign"
          element={
            <MainBusinessDashboard
              screen="campaign"
              nav="Pages / Campaign / Create"
              headerText="Home | Campaign"
            />
          }
        />
          <Route
          // path="/dashboard/business/campaign"
          path="/dashboard/business/campaign-new"
          element={
            <MainBusinessDashboard
              screen="campaign-new"
              nav="Pages / Campaign / Create"
              headerText="Home | Campaign"
            />
          }
        />

        <Route
            // path="/dashboard/business/campaign"
            path="/dashboard/business/creator/:creatorId"
            element={
              <MainBusinessDashboard
                screen="public_creator"
                nav="Pages / Creator / Profile"
                headerText="Creator | Profile"
              />
            }
          />

        <Route
          path="/dashboard/business/listCampaign"
          element={
            <MainBusinessDashboard
              screen="listCampaign"
              nav="Pages / Campaign / List"
              headerText="Home | Campaign"
            />
          }
        />

        <Route
          path="/dashboard/business/campaignDetail/:id"
          element={
            <MainBusinessDashboard
              screen="DetailCampaign"
              nav="Pages / Campaign / Detail"
              headerText="Detail | Campaign"
            />
          }
        />
          <Route
          path="/dashboard/business/profile"
          element={
            <MainBusinessDashboard
              screen="profile"
              nav="Pages / Profile "
              headerText="Business | Profile"
            />
          }
        />
         
        <Route
          path="/dashboard/business/orderDetail/:id"
          element={
            <MainBusinessDashboard
              screen="CampaignOrderDetail"
              nav="Pages / Campaign / Detail"
              headerText="Detail | Campaign"
            />
          }
        />

        <Route
          path="/dashboard/business/faq"
          element={
            <MainBusinessDashboard
              screen="faq"
              nav="Pages / FAQ"
              headerText="FAQ | Business"
            />
          } 
        />

          <Route
          path="/dashboard/business/marketing"
          element={
            <MainBusinessDashboard
              screen="marketing"
              nav="Pages / Marketing"
              headerText="Marketing | Business"
            />
          } 
        />
      </Routes>
      </NavigationProvider>
    </Router>
  );
}
