import { BsFillFileBarGraphFill, BsGraphUp } from "react-icons/bs";
import { MdOutlineAttachMoney, MdCampaign, MdPending } from "react-icons/md";
import { GiReceiveMoney, GiTakeMyMoney } from "react-icons/gi";
import SingleCardContent from "../../../component/singleCardContent/SingleCardContent";

import { useEffect, useState } from "react";
import CustomChart from "../../../component/custom_chart/customChart";
import { ChartData } from "chart.js";
import Table from "../../../component/table/table";
import { GridColDef } from "@mui/x-data-grid";
import { Campaign, CampaignStats, EarningsData, getEarningData, getExpectedAndActualViews, getTotalDashboardData } from "./controller.home";
import { set } from "react-hook-form";
import { CircularProgress } from "@mui/material";
import CallToAction from "../../../component/callToActionMessage/callToAction";
export default function DashboardHome() {
  const [fhfData, setFhfData] = useState<ChartData>({});

  const [weeklyOrder, setWeeklyOrder] = useState<ChartData>({});
  const [averageMonthlyEarning, setAverageMonthlyEarning] = useState<number>();
  const [campaginDetail, setCampaignDetail] = useState<CampaignStats>();

  const [socialMediaOrder, setSocialMediaOrder] = useState<GridColDef[]>([
    { field: "socialMedia", headerName: "Name", width: 200 },
    { field: "totalOrder", headerName: "Total Order", width: 200 },
    { field: "noOfOrder", headerName: "No of Order", width: 200 },
    { field: "money", headerName: "Money Earned", width: 200 },
    { field: "lastOrderDate", headerName: "last Order Date", width: 200 },
  ]);
  const [socialMediaOrderData, setSocialMediaOrderData] = useState<any>([
  
  ]);
  const [isloading, setIsLoading] = useState(false);
  const [earningData, setEarningData] = useState<EarningsData>();



  useEffect(() => {
    setIsLoading(true)
    getEarningData().then((data: EarningsData) => {
      setEarningData(data);
      setAverageMonthlyEarning(data?.montlyEarnings?.reduce((acc: number, curr: any) => acc + curr?.TotalEarnings, 0) / data?.montlyEarnings?.length)
      setWeeklyOrder({
        labels: data.montlyEarnings?.map((item) => item?.Month),

        datasets: [
          {
            label: "Earnings",
            // borderColor: theme.teritaryChartColor,
            backgroundColor: "#2B3674",

            data: data.montlyEarnings?.map((item) => item?.TotalEarnings),

            // barPercentage:20,
          },
        ],
      });
    });

    getTotalDashboardData().then((data: CampaignStats) => {

      setCampaignDetail(data);
      setIsLoading(false);
    });

    getExpectedAndActualViews().then((data: Campaign[]) => {
      setFhfData({
        labels: data.map((item) => "CP " + item?.CampaignId),

        datasets: [
          {
            label: "Expected View",
            // borderColor: theme.teritaryChartColor,
            backgroundColor: "#2B3674",

            data: data.map((item) => item?.ExpectedView),

            // barPercentage:20,
          },
          {
            label: "Actual View",
            // borderColor: theme.teritaryChartColor,
            backgroundColor: "#707EAE",
            borderWidth: 1,

            pointRadius: 5,
            pointBorderColor: "black",
            data: data.map((item) => item?.viewCount),

            // barPercentage:20,
          },
        ],
      });
    });

  }, []);

  return (
    <div className="homeMain">
      <div className="topContent">
        <SingleCardContent
          title={"Predicted Earnings"}
          amount={earningData?.totalEarnings?.TotalEarnings?.toLocaleString() || ""}
          icon={<GiReceiveMoney />}
        />
        <SingleCardContent
          title={"Monthly Earnings"}
          amount={averageMonthlyEarning?.toLocaleString() || ""}
          icon={<MdOutlineAttachMoney />}
        />
        <SingleCardContent
          title={"Total Campaigns"}
          amount={campaginDetail?.TotalCampaigns?.toLocaleString() || ""}
          icon={<MdCampaign />}
        />
        <SingleCardContent
          title={"Avilable Earnings"}
          amount={earningData?.availableEarningsForCashOut?.toLocaleString() || "0"}
          icon={<GiTakeMyMoney />}
        />
        <SingleCardContent
          title={"Pending campaign"}
          amount={campaginDetail?.PendingCampaigns?.toString() || ""}
          icon={<MdPending />}
        />
      </div>
      <div className="graphContent">
        <div className={"singleGraph"}>
          {isloading ?
            <CircularProgress />
            :
            <CustomChart props={fhfData} type={"bar"} />
          }
        </div>
        <div className={"singleGraph"}>
          {isloading ?
            <CircularProgress />
            : <CustomChart props={weeklyOrder || {}} type={"line"} />
          }

        </div>

        <div className={"singleGraph-wide"}>
          <Table columns={socialMediaOrder} data={socialMediaOrderData} />
        </div>
      </div>
    </div>
  );
}
