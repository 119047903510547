import React, { useEffect, useState } from 'react';
import axiosInstance from '../../../../axiosConfig';
import { url } from '../../../../util/constant';

interface Props {
    expectedView: string;
    setExpectedView: (value: string) => void;
    selectedNiche: number;
}

const ExpectedView: React.FC<Props> = ({ expectedView, setExpectedView ,selectedNiche }) => {

    const [estimatedBudget,setEstimatedBudget] = useState<number>(0);

    useEffect(()=>{
     
    },[])

    return (
        <div className="fadeIn">
            <p className='question'>  Expected View</p>

            <div className="flex flex-row items-center justify-start ">
                <input type='text'
                    className="expectedView"
                    placeholder="Enter expected View..."
                       value={expectedView}
                    onChange={(event: any) => {
                        console.log(event.target.value)
                        if(event.target.value == ""){
                            console.log("FINALHHJ1: ", event.target.value);
                            setExpectedView("");
                          

                        }else{
                            console.log("FINALHHJ: ", event.target.value);
                            let value = event.target.value.replace(/,/g, '');
                            let parsedValue = parseInt(value);
                            var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "")["access_token"];

                            axiosInstance.get(url+'/campaign/business/campaigns/budget/niche/'+selectedNiche+'/view/'+parsedValue,{
                                headers: {
                                    "Authorization": "Bearer " + userInfo   
                                }
                            }).then((res)=>{
                                setEstimatedBudget(res.data.estimatedBudget)
                            }).catch((err) => {
                                
                            })
                            setExpectedView(parsedValue.toLocaleString());
                        }
                    }}
                />
                {/* <p style={{ textAlign: 'start', color: 'gray', fontSize: 34 }}>Views</p> */}

            </div>
            <div style={{width:'100%',height:30,display:'flex',fontSize:22, justifyContent:'flex-start',alignItems:'flex-start',color: 'gray'}}>
                    <p>Estimated Budget: {" " +estimatedBudget +" "} Birr</p>
            </div>  
        </div>
    );
};

export default ExpectedView;
