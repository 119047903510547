

import { columnsStateInitializer } from "@mui/x-data-grid/internals";
import { View } from "lucide-react";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Link } from "react-router-dom";
import { url } from "../../../util/constant";
import axios from "axios";
import { AiFillDollarCircle, AiFillYoutube, AiOutlineFundView } from "react-icons/ai";
import axiosInstance from "../../../axiosConfig";
import { FaTelegram } from "react-icons/fa6";


export function getColumns(): GridColDef[] {    
  

 const columns: GridColDef[] = [
  
    { field: 'name', headerName: 'Company Name', width: 220,  headerClassName: 'centerHeader' },
    // { field: 'TotalPrice', headerName: 'Estimated Budget', width: 150,
    //   renderCell: (params:any) => {
    //     return (
    //       <span style={{color:'black',fontWeight:'bolder',justifyContent:'space-evenly', display:'flex',flexDirection:'row'}}>
    //         {parseInt(params.value).toLocaleString() }
    //         <AiFillDollarCircle style={{fontSize:23}} />
    //         </span>
    //     )
    // }
    //  },
    { field: 'ExpectedView', headerName: 'Estimated View', width: 250,
      renderCell: (params:any) => {
          return (
            <span style={{color:'black',fontWeight:'bolder',display:'flex',flexDirection:'row'}}>
              {params.value.toLocaleString() }
              <AiOutlineFundView style={{fontSize:23}} />
              </span>
          )
      }
     },
     { field: 'socialMediaId', headerName: 'Social Media', width: 150,
      renderCell: (params:any) => {
          return (
            <span style={{color:'black',fontWeight:'bolder',display:'flex',flexDirection:'row'}}>
              {params.value == 1? 
              <AiFillYoutube style={{fontSize:23}} /> :
              <FaTelegram style={{fontSize:23}} />  
            }
              </span>
          )
      }
     },
     
    { field: 'startDate', headerName: 'Start Date', width: 130 },

    { field: 'Status', headerName: 'status', width: 130,
        renderCell: (params:any) => {
         
            return(
                <span className={params.value === "Requested" ? "statusButton-pending" : params.value === "Rejected" ? "statusButton-rejected" :  "statusButton-active" } >
                    {params.value + ""}
                </span>
            );
        }
},
   
    {
      field: '',
      headerName: 'Action',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      renderCell:  (params:any) => {
       
        
        
        return (
          <div>
         
         
            <Link to={"/dashboard/order/"+params.row.id} className="actionButton" style={{background:'#090D25'}} >
                Detail
                </Link> 
                    
        
           </div>
        );
    },
      width: 160,
     
    },
  ];




return columns;
}


export function getCreatorCampaignDetail(id : number){
  var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "")["access_token"];

  return new Promise((resolve, reject) => {
      axiosInstance.get(url+'/creator/campaign/detail/'+ id, {
        headers: {
            "Authorization": "Bearer " + userInfo   
        }
    })
        .then(function (response) {
          console.log("TESTc: " ,response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
        resolve(error);
        });
      });  
}

export function getStatusOfTheCampaign(campaign_id: number){
  var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "")["access_token"];

  return new Promise((resolve, reject) => {
      axiosInstance.get(url+'/creator/campaign/is_accepted/'+ campaign_id, {
        headers: {
            "Authorization": "Bearer " + userInfo   
        }
    })
        .then(function (response) {
          console.log("TESTc: " , userInfo);
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
        resolve(error);
        });
      });  

}


export function getRows(): any {
  var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "")["access_token"];

  return new Promise((resolve, reject) => {
      axiosInstance.get(url+'/creator/statstic/campaign/list',{
        headers: {
            "Authorization": "Bearer " + userInfo   
        }
    })
        .then(function (response) {
          console.log("TEST: " ,response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
        resolve(error);
        });
      });  
  
}



export function manageOrderState(campaignDetail: any, callback: any){

}

export function isVideoUploaded(campaignDetail: any): boolean{
    var isVideoUploaded = false;
    //for(var x = 0 ; x < campaignDetail.length ; x++){
        if(campaignDetail.videoUrl != null){
            isVideoUploaded = true;
        }
   // }
    console.log("isVideoUploaded: ", campaignDetail);
    return isVideoUploaded;
}


export function acceptCampaign(column_id: number){
  var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "")["access_token"];

  return new Promise((resolve, reject) => {
      axiosInstance.get(url+'/campaign/acceptOrder/'+column_id+'/Accepted',{
        headers: {
            "Authorization": "Bearer " + userInfo   
        }
    })
        .then(function (response) {
          console.log("TESTc: " ,response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
        resolve(error);
        });
      });
}

export function rejectCampaign(column_id: number){
  var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "")["access_token"];

  return new Promise((resolve, reject) => {
      axios.get(url+'/campaign/acceptOrder/'+column_id+'/Rejected',{
        headers: {
            "Authorization": "Bearer " + userInfo   
        }
    })
        .then(function (response) {
          console.log("TESTc: " ,response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
        resolve(error);
        });
      });
}