import axios from "axios";
import { url } from "../../../../util/constant";
import axiosInstance from "../../../../axiosConfig";


export const creatorRowData = [{
    id: 1
    , name: "test"
    , age: 12
}
    , {
        id: 2
    , name: "test"
    , age: 12
}
    , {
        id: 3
    , name: "test"
    , age: 12
}
    , {
        id: 4
    , name: "test"
    , age: 12
}
    , {
        id: 5
    , name: "test"
    , age: 12
}
    , {
        id: 6
    , name: "test"
    , age: 12
}
    , {
        id: 7
    , name: "test"
    , age: 12
}
    , {
        id: 8
    , name: "test"
    , age: 12
}
    , {
        id: 9
    , name: "test"
    , age: 12
}
    , {
        id: 10
    , name: "test"
    , age: 12
}
    , {
        id: 11
    , name: "test"
    , age: 12
}
    , {
        id: 12
    , name: "test"
    , age: 12
}
    , {
        id: 13
    , name: "test"
    , age: 12
}
    , {
        id: 14
    , name: "test"
    , age: 12
}
    , {
        id: 15
    , name: "test"
    , age: 12
}
    , {
        id: 16
    , name: "test"
    , age: 12
}
    , {
        id: 17
    , name: "test"
    , age: 12
}
    , {
        id: 18
    , name: "test"
    , age: 12
}
    , {
        id: 19
    , name: "test"
    , age: 12
}
    , {
        id: 20
    , name: "test"
    , age: 12
}
    , {
        id: 21
    , name: "test"
    , age: 12
}
    , {
        id: 22
    , name: "test"
    , age: 12
}
    , {
        id: 23
    , name: "test"
    , age: 12
}
    , {
        id: 24
    , name: "test"
    , age: 12
}
    , {
        id: 25
    , name: "test"
    , age: 12
}
    , {
        id: 26
    , name: "test"
    , age: 12
}
    , {
        id: 27
    , name: "test"
    , age: 12
}
    , {
        id: 28
    , name: "test"
    , age: 12
}
    , {
        id: 29
    , name: "test"
    , age: 12
}
    , {
        id: 30
    , name: "test"
    , age: 12
}];

export function getCreatorRowData(campaignId: number) {
    // api call to get data
    var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "")["access_token"];

    return new Promise((resolve, reject) => {
        axiosInstance.get(url+'/business/campaign/creator/'+campaignId,{
          headers: {
              "Authorization": "Bearer " + userInfo   
          } 
      })
          .then(function (response) {
            console.log("TEST: " ,response.data);
            resolve(response.data);
          })
          .catch(function (error) {
            console.log(error);
          resolve(error);
          });
        });   
}

export function getCreatorRowDataTelegram(campaignId: number) {
    // api call to get data
    var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "")["access_token"];

    return new Promise((resolve, reject) => {
        axiosInstance.get(url+'/business/campaign/creator/telegram/'+campaignId,{
          headers: {
              "Authorization": "Bearer " + userInfo   
          }
      })
          .then(function (response) {
            console.log("TEST: " ,response.data);
            resolve(response.data);
          })
          .catch(function (error) {
            console.log(error);
          resolve(error);
          });
        });   
}